export interface Location {
  id: string;
  city: string;
  state: string;
  slug: string;
  nfl?: {
    team: string;
    stadium: string;
  };
}

export const locations: Location[] = [
  // Original cities
  { id: 'la-rams', city: 'Los Angeles', state: 'CA', slug: 'los-angeles-rams', nfl: { team: 'Rams', stadium: 'SoFi Stadium' } },
  { id: 'murrieta', city: 'Murrieta', state: 'CA', slug: 'murrieta' },
  { id: 'menifee', city: 'Menifee', state: 'CA', slug: 'menifee' },
  { id: 'temecula', city: 'Temecula', state: 'CA', slug: 'temecula' },
  { id: 'winchester', city: 'Winchester', state: 'CA', slug: 'winchester' },
  
  // NFL Cities - AFC East
  { id: 'buffalo', city: 'Buffalo', state: 'NY', slug: 'buffalo', nfl: { team: 'Bills', stadium: 'Highmark Stadium' } },
  { id: 'miami', city: 'Miami', state: 'FL', slug: 'miami', nfl: { team: 'Dolphins', stadium: 'Hard Rock Stadium' } },
  { id: 'boston', city: 'Boston', state: 'MA', slug: 'boston', nfl: { team: 'Patriots', stadium: 'Gillette Stadium' } },
  { id: 'ny-jets', city: 'New York', state: 'NY', slug: 'new-york-jets', nfl: { team: 'Jets', stadium: 'MetLife Stadium' } },
  { id: 'ny-giants', city: 'New York', state: 'NY', slug: 'new-york-giants', nfl: { team: 'Giants', stadium: 'MetLife Stadium' } },

  // AFC North
  { id: 'baltimore', city: 'Baltimore', state: 'MD', slug: 'baltimore', nfl: { team: 'Ravens', stadium: 'M&T Bank Stadium' } },
  { id: 'cincinnati', city: 'Cincinnati', state: 'OH', slug: 'cincinnati', nfl: { team: 'Bengals', stadium: 'Paycor Stadium' } },
  { id: 'cleveland', city: 'Cleveland', state: 'OH', slug: 'cleveland', nfl: { team: 'Browns', stadium: 'Cleveland Browns Stadium' } },
  { id: 'pittsburgh', city: 'Pittsburgh', state: 'PA', slug: 'pittsburgh', nfl: { team: 'Steelers', stadium: 'Acrisure Stadium' } },

  // AFC South
  { id: 'houston', city: 'Houston', state: 'TX', slug: 'houston', nfl: { team: 'Texans', stadium: 'NRG Stadium' } },
  { id: 'indianapolis', city: 'Indianapolis', state: 'IN', slug: 'indianapolis', nfl: { team: 'Colts', stadium: 'Lucas Oil Stadium' } },
  { id: 'jacksonville', city: 'Jacksonville', state: 'FL', slug: 'jacksonville', nfl: { team: 'Jaguars', stadium: 'TIAA Bank Field' } },
  { id: 'nashville', city: 'Nashville', state: 'TN', slug: 'nashville', nfl: { team: 'Titans', stadium: 'Nissan Stadium' } },

  // AFC West
  { id: 'denver', city: 'Denver', state: 'CO', slug: 'denver', nfl: { team: 'Broncos', stadium: 'Empower Field at Mile High' } },
  { id: 'kansas-city', city: 'Kansas City', state: 'MO', slug: 'kansas-city', nfl: { team: 'Chiefs', stadium: 'Arrowhead Stadium' } },
  { id: 'las-vegas', city: 'Las Vegas', state: 'NV', slug: 'las-vegas', nfl: { team: 'Raiders', stadium: 'Allegiant Stadium' } },
  { id: 'la-chargers', city: 'Los Angeles', state: 'CA', slug: 'los-angeles-chargers', nfl: { team: 'Chargers', stadium: 'SoFi Stadium' } },

  // NFC East
  { id: 'dallas', city: 'Dallas', state: 'TX', slug: 'dallas', nfl: { team: 'Cowboys', stadium: 'AT&T Stadium' } },
  { id: 'philadelphia', city: 'Philadelphia', state: 'PA', slug: 'philadelphia', nfl: { team: 'Eagles', stadium: 'Lincoln Financial Field' } },
  { id: 'washington', city: 'Washington', state: 'DC', slug: 'washington', nfl: { team: 'Commanders', stadium: 'FedExField' } },

  // NFC North
  { id: 'chicago', city: 'Chicago', state: 'IL', slug: 'chicago', nfl: { team: 'Bears', stadium: 'Soldier Field' } },
  { id: 'detroit', city: 'Detroit', state: 'MI', slug: 'detroit', nfl: { team: 'Lions', stadium: 'Ford Field' } },
  { id: 'green-bay', city: 'Green Bay', state: 'WI', slug: 'green-bay', nfl: { team: 'Packers', stadium: 'Lambeau Field' } },
  { id: 'minneapolis', city: 'Minneapolis', state: 'MN', slug: 'minneapolis', nfl: { team: 'Vikings', stadium: 'U.S. Bank Stadium' } },

  // NFC South
  { id: 'atlanta', city: 'Atlanta', state: 'GA', slug: 'atlanta', nfl: { team: 'Falcons', stadium: 'Mercedes-Benz Stadium' } },
  { id: 'charlotte', city: 'Charlotte', state: 'NC', slug: 'charlotte', nfl: { team: 'Panthers', stadium: 'Bank of America Stadium' } },
  { id: 'new-orleans', city: 'New Orleans', state: 'LA', slug: 'new-orleans', nfl: { team: 'Saints', stadium: 'Caesars Superdome' } },
  { id: 'tampa', city: 'Tampa', state: 'FL', slug: 'tampa', nfl: { team: 'Buccaneers', stadium: 'Raymond James Stadium' } },

  // NFC West
  { id: 'phoenix', city: 'Phoenix', state: 'AZ', slug: 'phoenix', nfl: { team: 'Cardinals', stadium: 'State Farm Stadium' } },
  { id: 'san-francisco', city: 'San Francisco', state: 'CA', slug: 'san-francisco', nfl: { team: '49ers', stadium: "Levi's Stadium" } },
  { id: 'seattle', city: 'Seattle', state: 'WA', slug: 'seattle', nfl: { team: 'Seahawks', stadium: 'Lumen Field' } }
];