import { createBrowserRouter } from 'react-router-dom';
import App from './App';
import { AdminLayout } from './layouts/AdminLayout';
import { Home } from './pages/Home';
import { CityCategories } from './pages/CityCategories';
import { CategoryProfessionals } from './pages/CategoryProfessionals';
import { ProfessionalProfile } from './pages/ProfessionalProfile';
import { AdminDashboard } from './pages/AdminDashboard';
import { UserManagement } from './components/admin/UserManagement';
import { ReviewModeration } from './components/admin/ReviewModeration';
import { Analytics } from './components/admin/Analytics';
import { Settings } from './components/admin/Settings';
import { BusinessRegistration } from './pages/BusinessRegistration';
import { ErrorBoundary } from './components/ErrorBoundary';
import { AboutUs } from './pages/AboutUs';
import { ContactUs } from './pages/ContactUs';
import { PrivacyPolicy } from './pages/PrivacyPolicy';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'about',
        element: <AboutUs />,
      },
      {
        path: 'contact',
        element: <ContactUs />,
      },
      {
        path: 'privacy',
        element: <PrivacyPolicy />,
      },
      {
        path: ':citySlug',
        element: <CityCategories />,
      },
      {
        path: ':citySlug/:mainCategoryId',
        element: <CategoryProfessionals />,
      },
      {
        path: ':citySlug/:mainCategoryId/:professionalId',
        element: <ProfessionalProfile />,
      },
      {
        path: 'register-business',
        element: <BusinessRegistration />,
      },
    ],
  },
  {
    path: '/admin',
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <AdminDashboard />,
      },
      {
        path: 'users',
        element: <UserManagement />,
      },
      {
        path: 'reviews',
        element: <ReviewModeration />,
      },
      {
        path: 'analytics',
        element: <Analytics />,
      },
      {
        path: 'settings',
        element: <Settings />,
      },
    ],
  },
]);