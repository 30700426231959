import React from 'react';
import { Helmet } from 'react-helmet-async';

export function GoogleSearchConsole() {
  return (
    <Helmet>
      <meta 
        name="google-site-verification" 
        content="YOUR_VERIFICATION_CODE_HERE"
      />
    </Helmet>
  );
}