import React from 'react';
import { SEOHead } from '../components/SEOHead';
import { Card, CardContent } from '../components/ui/card';
import { Building2, Users, Award, Target } from 'lucide-react';

export function AboutUs() {
  return (
    <>
      <SEOHead
        title="About Us | Local Knows"
        description="Learn about Local Knows - your trusted platform for connecting with local service professionals. Discover our mission, values, and commitment to quality service."
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            About Local Knows
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Connecting homeowners with trusted local professionals since 2020. We're committed to making home services accessible, reliable, and hassle-free.
          </p>
        </div>

        {/* Mission & Values */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          <Card>
            <CardContent className="p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Our Mission</h2>
              <p className="text-gray-600">
                To revolutionize how homeowners connect with service professionals by providing a trusted platform that emphasizes quality, reliability, and transparency.
              </p>
            </CardContent>
          </Card>
          <Card>
            <CardContent className="p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Our Vision</h2>
              <p className="text-gray-600">
                To be the most trusted platform for home services, where every connection leads to exceptional service and complete satisfaction.
              </p>
            </CardContent>
          </Card>
        </div>

        {/* Core Values */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 text-center mb-8">Our Core Values</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              {
                icon: Building2,
                title: "Trust",
                description: "We build trust through transparency and accountability"
              },
              {
                icon: Users,
                title: "Community",
                description: "We foster strong relationships within our community"
              },
              {
                icon: Award,
                title: "Excellence",
                description: "We strive for excellence in everything we do"
              },
              {
                icon: Target,
                title: "Innovation",
                description: "We continuously innovate to improve our services"
              }
            ].map((value, index) => (
              <Card key={index}>
                <CardContent className="p-6 text-center">
                  <value.icon className="w-12 h-12 text-blue-600 mx-auto mb-4" />
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">
                    {value.title}
                  </h3>
                  <p className="text-gray-600">{value.description}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>

        {/* Team Section */}
        <div>
          <h2 className="text-3xl font-bold text-gray-900 text-center mb-8">Our Leadership Team</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                name: "Sarah Johnson",
                role: "CEO & Founder",
                image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=400"
              },
              {
                name: "Michael Chen",
                role: "Chief Technology Officer",
                image: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=400"
              },
              {
                name: "Emily Davis",
                role: "Head of Operations",
                image: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=400"
              }
            ].map((member, index) => (
              <Card key={index}>
                <CardContent className="p-6 text-center">
                  <img
                    src={member.image}
                    alt={member.name}
                    className="w-32 h-32 rounded-full mx-auto mb-4 object-cover"
                  />
                  <h3 className="text-xl font-semibold text-gray-900">
                    {member.name}
                  </h3>
                  <p className="text-gray-600">{member.role}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}