import React from 'react';
import { Link } from 'react-router-dom';
import { locations } from '../data/locations';

export function CityGrid() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
      {locations.map((location) => {
        // Generate the correct URL slug based on whether it's an NFL city
        const citySlug = location.nfl 
          ? `${location.city.toLowerCase().replace(/\s+/g, '-')}-${location.nfl.team.toLowerCase()}`
          : location.slug;

        return (
          <Link
            key={location.id}
            to={`/${citySlug}`}
            className="p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow"
          >
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-lg font-semibold">{location.city}</h3>
                <p className="text-sm text-gray-600">{location.state}</p>
              </div>
              {location.nfl && (
                <div className="text-sm text-gray-500">
                  <p>Home of {location.nfl.team}</p>
                </div>
              )}
            </div>
          </Link>
        );
      })}
    </div>
  );
}